import React from 'react';

const Home = () => {
    return (
        

        <div className="d-flex justify-content-center align-items-center" style={{height: '40vh'}}>
        <div className= "text-center mt-5">
          <h5> Welcome to </h5>
          <h1 className='Hero'> Patentify </h1>
          <h4> AI Assisted Patent Labeling </h4>
        </div>

        </div>
        
    );
};

export default Home;